<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            &nbsp;
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <router-link
                :to="{ name: redirectEdit, params: { id: null, origen: 'List Empresas', action: 'Create' } }"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                >
                  <span class="text-nowrap">Agregar Empresa</span>
                </b-button>
              </router-link>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12">
            <list-table-data
              :table-columns="tableColumns"
              :table-data="items"
              :redirect-edit="redirectEdit"
              :redirect-show="redirectShow"
              :origen="catalogoName"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import ListTableData from '@/components/ListTableData.vue'
import { getCompanies } from '@/utils/companies'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ListTableData,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false)

    return {
      // Sidebar
      isAddNewUserSidebarActive,
    }
  },
  data() {
    return {
      catalogoName: 'Empresa',
      redirectEdit: 'catalogos-empresa-show',
      redirectShow: 'catalogos-empresa-show',
      items: [],
      tableColumns: [
        {
          label: 'Nombre',
          field: 'name',
        },
        {
          label: 'Municipio',
          field: 'obj_municipio.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'active',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Acciones',
          field: 'acciones',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
    }
  },
  async beforeMount() {
    try {
      this.items = await getCompanies()
      if (this.items === null) this.items = []
    } catch (err) {
      console.error(`Error en cargar ${this.catalogoName}`, err)
      this.items = []
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
